<template>
  <div>
    <div class="mb-5">
      <h1 class="text-[#1B224B] text-[32px] font-bold">Provide details of this job opening</h1>
      <small class="text-[#1B224B] text-[15px]">Attract top talent: Unveiling a rewarding career opportunity.</small>
    </div>

    <div class="py-5">
      <div class="col-span-6 sm:col-span-3 mb-8">
        <label for="role-type" class="block text-base font-semibold text-[#090E11]/70"
          ><span class="text-[#FF2449] text-[16px] font-bold">*</span> What type of role is this?</label
        >
        <select
          v-model="details.type"
          @change="$emit('updateReach')"
          id="role-type"
          name="role-type"
          autocomplete="role-type"
          class="mt-1 block w-full border-1 border-[#1B224B1A] p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
        >
          <option disabled value="">Select One</option>
          <option class="" v-for="item in enums?.job_types" :key="item.key" :value="item.key">
            {{ item.value }}
          </option>
        </select>
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70"
          ><span class="text-[#FF2449] text-[16px] font-bold">*</span> Job opening title</label
        >
        <input
          v-model="details.role_title"
          name="role_title"
          type="text"
          id="email-address-icon"
          class="mt-1 block w-full border-1 border-[#1B224B1A] p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
        />
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70"
          ><span class="text-[#FF2449] text-[16px] font-bold">*</span> Job role</label
        >
        <select
          name="role"
          id="role"
          autocomplete="job-role"
          v-model="details.title"
          class="mt-1 block w-full border-1 border-[#1B224B1A] p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
        >
          <option disabled value="">Select One</option>
          <option v-for="item in jobTitles" :key="item.key" :value="item.key" :selected="details.title == item.key">
            {{ item.value }}
          </option>
        </select>
      </div>

      <div class="col-span-6 mb-8">
        <label for="job" class="block text-base font-semibold text-[#090E11]/70"
          ><span class="text-[#FF2449] text-[16px] font-bold">*</span> Job Description</label
        >
        <QuillEditor
          v-model:content="details.description"
          placeholder="Tell us about the responsibilities and qualifications"
          contentType="html"
          class="bg-white"
          theme="snow"
          toolbar="full"
        />
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70">Invite collaborators</label>
        <div class="mt-1 mb-3">
          <small class="text-[#494E6F] text-[12px]"
            >Invite team leads or hiring managers from your company who will have access to this position.</small
          >
        </div>
        <vue3-tags-input
          :tags="details.collaborators"
          :add-tag-on-keys="[13]"
          @on-tags-changed="handleChangeTag"
          placeholder="e.g johndoe@example.com"
          class="mt-1 block w-full border-1 border-[#1B224B1A] py-4 px-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
        />
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70"
          ><span class="text-[#FF2449] text-[16px] font-bold">*</span> Level of experience</label
        >
        <div class="flex flex-wrap mt-5">
          <div class="flex items-center mr-4 mb-4 md:mb-1">
            <input
              v-model="details.level_of_experience"
              @change="$emit('updateReach')"
              type="radio"
              value="entry_level"
              name="experience"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Entry Level</label>
          </div>

          <div class="flex items-center mr-4 mb-4 md:mb-1">
            <input
              v-model="details.level_of_experience"
              @change="$emit('updateReach')"
              type="radio"
              value="junior_level"
              name="experience"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Junior Level</label>
          </div>

          <div class="flex items-center mr-4 mb-4 md:mb-1">
            <input
              v-model="details.level_of_experience"
              @change="$emit('updateReach')"
              type="radio"
              value="mid_level"
              name="experience"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mid Level</label>
          </div>

          <div class="flex items-center mr-4 mb-4 md:mb-1">
            <input
              v-model="details.level_of_experience"
              @change="$emit('updateReach')"
              type="radio"
              value="senior_level"
              name="experience"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Senior Level</label>
          </div>

          <div class="flex items-center mr-4 mb-4 md:mb-1">
            <input
              v-model="details.level_of_experience"
              @change="$emit('updateReach')"
              type="radio"
              value="lead"
              name="experience"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lead</label>
          </div>

          <div class="flex items-center mr-4 mb-4 md:mb-1">
            <input
              v-model="details.level_of_experience"
              @change="$emit('updateReach')"
              type="radio"
              value="executive"
              name="experience"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Executive</label>
          </div>
        </div>
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70">Must-Haves / Non-Negotiables</label>
        <div class="mt-1 mb-3">
          <small class="text-[#494E6F] text-[12px]">The section should include skills, training, education, abilities, experience etc</small>
        </div>

        <div class="mb-3">
          <div class="flex items-center mb-5">
            <input
              v-model="details.must_have[0]"
              type="text"
              placeholder="Must have #1"
              class="mt-1 block w-full border-1 border-[#1B224B1A] p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm mr-3"
            />
          </div>
          <template v-if="details.must_have.length > 1">
            <div class="flex items-center mb-5" v-for="(item, index) in details?.must_have?.slice(1)" :key="index">
              <input
                v-model="details.must_have[index + 1]"
                type="text"
                :placeholder="`Must have #${index + 2}`"
                class="mt-1 block w-full border-1 border-[#1B224B1A] p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm mr-3"
              />
              <img src="@/assets/icons/cancel.svg" @click="details?.must_have?.splice(index + 1, 1)" class="cursor-pointer w-[24px] h-[24px]" />
            </div>
          </template>
        </div>

        <div
          class="inline-flex items-center cursor-pointer"
          @click="details.must_have.length == 0 ? (details.must_have[1] = '') : details.must_have.push('')"
        >
          <img src="@/assets/icons/plus.svg" class="mr-2" alt="" />
          <span class="inline-block text-[16px] text-[#E91868] font-semibold">Add</span>
        </div>
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70"
          ><span class="text-[#FF2449] text-[16px] font-bold">*</span> I'm looking for someone whose speciality is</label
        >
        <div class="mt-1 mb-3">
          <small class="text-[#494E6F] text-[12px]">What kind of a position do you want to fill</small>
        </div>

        <vue3-tags-input
          :tags="details.skills"
          :add-tag-on-keys="[13]"
          @on-tags-changed="handleChangeSkillsTag"
          placeholder="e.g UI/UX"
          class="mt-1 block w-full border-1 border-[#1B224B1A] py-2 px-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
        />

        <div class="flex flex-wrap items-center mt-3" v-if="details.skills.length">
          <div
            class="mr-4 mb-2 flex items-center rounded-[30px] px-[16px] py-[8px] bg-[#F3F3F5] font-medium text-[15px]"
            v-for="(item, i) in details.skills"
            :key="i"
          >
            <span class="mr-1">{{ item }}</span>
            <img @click="details.skills.splice(i, 1)" src="@/assets/icons/cancel.svg" class="cursor-pointer" />
          </div>
        </div>
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70">Position location (country)</label>
        <div class="mt-1 mb-3">
          <small class="text-[#494E6F] text-[12px]">In which city is this position located</small>
        </div>

        <Multiselect
          @change="getStates"
          v-model="details.country"
          :object="true"
          label="value"
          :searchable="true"
          :close-on-select="true"
          :options="countries"
          class="mt-1 block w-full border-1 border-[#1B224B1A] py-2 p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
        />
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70">Position location (state)</label>
        <div class="mt-1 mb-3">
          <small class="text-[#494E6F] text-[12px]">In which city is this position located</small>
        </div>

        <Multiselect
          @change="updateState"
          v-model="details.state"
          :object="true"
          :searchable="true"
          label="value"
          :close-on-select="true"
          :options="states"
          class="mt-1 block w-full border-1 border-[#1B224B1A] py-2 p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
        />
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70">Location</label>
        <div class="mt-1 mb-3">
          <small class="text-[#494E6F] text-[12px]">Do you want to include people who are interested in remote or hybrid work.</small>
        </div>

        <div class="flex flex-wrap mt-5">
          <div class="flex items-center mr-4" v-for="item in enums?.job_work_types" :key="item.key">
            <input
              v-model="details.work_type"
              type="radio"
              :value="item.key"
              name="work_type"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ item.value }}</label>
          </div>
        </div>
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70">Relocation</label>
        <div class="mt-1 mb-3">
          <small class="text-[#494E6F] text-[12px]">Do you want to include people who are interested in remote or hybrid work.</small>
        </div>

        <div class="flex flex-wrap mt-5">
          <div class="flex items-center mr-4">
            <input
              v-model="details.relocation"
              type="radio"
              value="Remote Working"
              name="relocation"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remote working</label>
          </div>

          <div class="flex items-center mr-4">
            <input
              v-model="details.relocation"
              type="radio"
              value="Open to Relocation"
              name="relocation"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Open to Relocation</label>
          </div>
        </div>
      </div>

      <div class="col-span-6 mb-8">
        <label for="role" class="block text-base font-semibold text-[#090E11]/70">Relocation Package</label>
        <div class="mt-1 mb-3">
          <small class="text-[#494E6F] text-[12px]">Choose one or many</small>
        </div>

        <Multiselect
          v-model="details.relocation_package"
          :searchable="true"
          mode="multiple"
          :close-on-select="false"
          :options="['Apartment', 'Visa application', 'Transport fee']"
          class="mt-1 block w-full border-1 border-[#1B224B1A] py-2 p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
        />

        <div class="flex flex-wrap items-center mt-3" v-if="details?.relocation_package.length">
          <div
            class="mr-4 mb-2 flex items-center rounded-[30px] px-[16px] py-[8px] bg-[#F3F3F5] font-medium text-[15px]"
            v-for="(item, i) in details?.relocation_package"
            :key="i"
          >
            <span class="mr-1">{{ item }}</span>
            <img @click="details?.relocation_package.splice(i, 1)" src="@/assets/icons/cancel.svg" class="cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Vue3TagsInput from 'vue3-tags-input';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import Multiselect from '@vueform/multiselect';

const props = defineProps({
  jobTitles: { type: Array, default: [] },
  countries: { type: Array, default: [] },
  enums: { type: Array, default: [] },
  details: { type: Object, default: {} },
  jobTitle: { type: Array, default: [] },
});

const store = useStore();

const states = computed(() => {
  return store.getters['global/getStates'];
});

const handleChangeTag = (tags) => {
  props.details.collaborators = tags;
};

const handleChangeSkillsTag = (tags) => {
  props.details.skills = tags;
};

const getStates = async (country) => {
  if (!country?.key) {
    return
  }
  await store.dispatch('global/getStates', country.key);
  props.details.country_id = country.id;
};

const updateState = async (state) => {
  if (!state?.id) {
    return
  }
  props.details.state_id = state.id;
};

onMounted(() => {
  if (!props.details.country?.key) return;
  getStates(props.details.country.key);
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.v3ti::v-deep {
  .v3ti-tag {
    border-radius: 5px;
    background: rgba(27, 34, 75, 0.05);
    color: #090e11;
    text-transform: lowercase;

    a {
      color: #090e11;
      font-weight: 500;
    }
  }
}
</style>
