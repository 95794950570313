<template>
  <div class="bg-[#FCFCFC]">
    <ul class="hidden md:flex justify-center my-10 gap-x-4">
      <li class="cursor-pointer" v-for="(item, index) in tabs" :key="index" @click="tab = index + 1">
        <span :class="[tab == index + 1 ? 'text-white bg-[#F0386A]' : 'text-white bg-[#1B224B]/[.2]', 'mr-1 px-2 rounded-full']">{{
          index + 1
        }}</span>
        <span :class="[tab == index + 1 ? 'text-[#1B224B]' : 'text-[#1B224B]/[.4]', '']">{{ item }}</span>
      </li>
    </ul>

    <!-- Mobile titles -->
    <ul class="flex justify-center my-10 gap-x-4 md:hidden">
      <template v-for="(item, index) in tabs" :key="index">
        <li class="cursor-pointer" @click="tab = index + 1" v-if="tab == index + 1">
          <span :class="[tab == index + 1 ? 'text-white bg-[#F0386A]' : 'text-white bg-[#1B224B]/[.2]', 'mr-1 px-2 rounded-full']">{{
            index + 1
          }}</span>
          <span :class="[tab == index + 1 ? 'text-[#1B224B]' : 'text-[#1B224B]/[.4]', '']">{{ item }}</span>
        </li>
      </template>
    </ul>

    <div class="px-3 lg:px-52 md:flex gap-x-24 my-16" v-if="tab != tabs.length">
      <div class="w-full md:w-3/5">
        <job-details
          v-if="tab == 1"
          :enums="enums"
          :details="details"
          :job-titles="jobTitles"
          :countries="countries"
          @update-reach="getPotentialReach"
        />

        <salary v-else-if="tab == 2" :enums="enums" :details="details" :countries="countries" />

        <pitch-message v-else-if="tab == 3" :details="details" :userDetails="userDetails" />

        <application v-else-if="tab == 4" :details="details" />

        <div class="">
          <button
            type="button"
            class="bg-brand-primary text-white py-4 px-10 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
            @click="proceed"
          >
            Continue
          </button>
        </div>
      </div>
      <div class="mt-[60px] md:w-2/5 lg:w-2/5 md:mt-0">
        <div class="border border-[#1B224B1A] rounded-b-2xl">
          <div class="text-center p-6 bg-[#1B224B] rounded-b-2xl">
            <h2 class="font-bold text-white text-[24px]">Targeting Data</h2>
          </div>
          <div class="p-6">
            <div class="mb-6">
              <small class="text-[#1B224B] text-[15px]">Potential Reach</small>
              <div class="flex items-center my-5">
                <div class="w-[40px] h-[40px] rounded-full bg-[#7440E2]/[.10] flex items-center justify-center mr-4">
                  <img src="@/assets/icons/users2.svg" />
                </div>
                <h4 class="text-[#1B224B] font-bold text-[18px]">
                  {{ potentialReach }}
                </h4>
              </div>
              <small class="text-[#1B224B] text-[15px]">I’m looking for someone</small>
            </div>

            <div class="flex border-b border-[#E6E6EA] mb-6 pb-6">
              <div class="w-[40px] h-[40px] rounded-full bg-[#7440E2]/[.10] flex items-center justify-center mr-4">
                <img src="@/assets/icons/users2.svg" />
              </div>
              <div>
                <small class="text-[#1B224B] text-[15px]">Who works in</small>
                <h4 class="text-[#1B224B] font-bold text-[18px]">
                  {{ roleTitle }}
                </h4>
              </div>
            </div>

            <div class="flex border-b border-[#E6E6EA] mb-6 pb-6">
              <div class="w-[40px] h-[40px] rounded-full bg-[#7440E2]/[.10] flex items-center justify-center mr-4">
                <img src="@/assets/icons/users2.svg" />
              </div>
              <div>
                <small class="text-[#1B224B] text-[15px]">Who specialize in</small>
                <h4 class="text-[#1B224B] font-bold text-[18px]">
                  {{ specialize }}
                </h4>
              </div>
            </div>

            <div class="flex mb-6">
              <div class="w-[40px] h-[40px] rounded-full bg-[#7440E2]/[.10] flex items-center justify-center mr-4">
                <img src="@/assets/icons/users2.svg" />
              </div>
              <div>
                <small class="text-[#1B224B] text-[15px]">We are looking to fill a</small>
                <h4 class="text-[#1B224B] font-bold text-[18px]">
                  {{ details.level_of_experience || 'N/A' }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-6 lg:px-80" v-else>
      <div class="bg-white py-6 px-10 rounded-lg mb-6">
        <review :details="details" :job-titles="jobTitles" :potential-reach="potentialReach" />

        <button
          type="button"
          class="bg-brand-primary text-white py-4 px-10 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
          @click="proceed"
        >
          Publish
        </button>
      </div>
    </div>
  </div>

  <Success v-if="formSubmitted" :job="details" />

  <loading v-if="loading" />
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import { errorMessage, alert } from '@/utils/helper';
import JobDetails from '@/components/job/JobDetails.vue';
import Salary from '@/components/job/Salary.vue';
import PitchMessage from '@/components/job/PitchMessage.vue';
import Application from '@/components/job/Application.vue';
import Review from '@/components/job/Review.vue';
import Loading from '@/components/LoadingOverlay.vue';
import Success from './Success.vue';
import router from '@/router/index';

const props = defineProps({
  action_type: { type: String, default: 'add' },
});

const store = useStore();

const route = useRoute();

const formSubmitted = ref(false);
const loading = ref(false);
const jobTitles = ref([]);
const countries = ref([]);
const enums = ref([]);
const tab = ref(1);
const tabs = ref(['Job Information', 'Salary Range', 'Pitch Message', 'Application', 'Review & Publish']);

const details = ref({
  fid: '',
  role_title: '',
  title: '',
  description: '',
  skills: [],
  type: '',
  work_type: '',
  pay_period: 'per_year',
  salary_currency: '',
  salary_min: 0,
  salary_max: 0,
  status: 'open',
  level_of_experience: '',
  country_id: '',
  state_id: '',
  state: {},
  country: {},
  relocation: '',
  must_have: [],
  relocation_package: [],
  apply_in_external_website: false,
  external_website_url: '',
  apply_through_careerbuddy: true,
  collaborators: [],
  pitch_message: '',
  automated_interview_questions: [],
  employer: {},
});

const rules = ref([
  {
    role_title: {
      required: helpers.withMessage('Role title field is required', required),
      $autoDirty: true,
    },
    title: {
      required: helpers.withMessage('Select a category', required),
      $autoDirty: true,
    },
    description: {
      required: helpers.withMessage('Enter job description', required),
      $autoDirty: true,
    },
    skills: {
      required: helpers.withMessage('Enter at least one skill', required),
      $autoDirty: true,
    },
    type: {
      required: helpers.withMessage('Select role type', required),
      $autoDirty: true,
    },
    work_type: {
      required: helpers.withMessage('Select job location', required),
      $autoDirty: true,
    },
  },
  {
    salary_currency: {
      required: helpers.withMessage('Select a currency', required),
      $autoDirty: true,
    },
    pay_period: { required: helpers.withMessage('Select a range', required) },
    salary_min: {
      required: helpers.withMessage('Salary field is required', required),
      $autoDirty: true,
    },
    salary_max: {
      required: helpers.withMessage('Salary field is required', required),
      $autoDirty: true,
    },
  },
]);

const roleTitle = computed(() => {
  return jobTitles.value.find((item) => item.key == details.value.title)?.value || 'N/A';
});

const specialize = computed(() => {
  return details.value.skills.join(' / ') || 'N/A';
});

const potentialReach = computed(() => {
  return store.getters['global/getAllTalents'].length || 0;
});

const userDetails = computed(() => {
  return store.getters['auth/userDetails'];
});

const proceed = async () => {
  //Validate data
  const tabRules = rules.value[tab.value < 5 ? tab.value - 1 : 0];
  if (tabRules) {
    const v$ = useValidate(tabRules, details);
    v$.value.$validate();
    v$.value.$touch();
    if (v$.value.$error) {
      alert(v$.value.$errors[0].$message, 'error');
      return;
    }
  }

  //validate must have
  if (tab.value == 1) {
    const values = [];
    details.value?.must_have.map((item, index) => (!item.trim() ? details.value?.must_have.splice(index, 1) : values.push(item)));
    if (!details.value?.must_have.length || !values.length) {
      alert('Enter at least one must have', 'error');
      return;
    }
  }

  if (tab.value < 5) {
    tab.value += 1;
  } else {
    //save form data
    await handleSubmit();
  }
};

const handleSubmit = async () => {
  try {
    loading.value = true;
    let msg = 'Job edited successfully';
    if (props.action_type === 'update') {
      await store.dispatch('global/updateSingleJob', {
        id: route.params.id,
        details: details.value,
      });
    } else {
      const job = await store.dispatch('global/createJob', details.value);
      details.value = {
        ...details.value,
        ...job,
      };
      msg = 'Job created';
    }

    formSubmitted.value = true;
    //router.push('/jobs')
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
};

const getPotentialReach = async () => {
  const { salary_currency, salary_min, salary_max, level_of_experience, type, title } = details.value;

  const params = {
    job_type: type,
    salary_currency,
    salary_min,
    salary_max,
    job_category: title,
    level_of_experience,
  };

  try {
    await store.dispatch('global/getAllTalents', { params });
  } catch (error) {
    loading.value = false;
  }
};

onMounted(async () => {
  countries.value = store.getters['global/getCountries'];
  if (!Array.isArray(countries.value) || countries.value?.length < 1) {
    countries.value = (await store.dispatch('global/getCountries')) || [];
  }

  const jobCats = store.getters['global/getJobCategories'];
  jobTitles.value = !Array.isArray(jobCats) || jobCats?.length < 1 ? await store.dispatch('global/getJobCategories') : jobCats;

  const enu = store.getters['global/getEnumDetails'];
  enums.value = !Array.isArray(enu) || enu?.length < 1 ? await store.dispatch('global/getEnums') : enu;

  if (props.action_type === 'update') {
    loading.value = true;
    try {
      const jobDetails = await store.dispatch('global/getSingleJob', route.params.id);

      const must_have = jobDetails.must_have || [];

      const automated_interview_questions = jobDetails.automated_interview_questions || [];

      const country = jobDetails?.country?.id
        ? {
            id: jobDetails?.country.id,
            key: jobDetails?.country.code,
            value: jobDetails?.country.name,
          }
        : {};
      const state = jobDetails?.state?.id
        ? {
            id: jobDetails?.state.id,
            key: jobDetails?.state.code,
            value: jobDetails?.state.name,
          }
        : {};
      details.value = {
        ...details.value,
        ...jobDetails,
        ...{
          collaborators: jobDetails?.collaborators.map((item) => item.employer_user.email) || [],
          title: jobDetails.job_category,
          salary_min: jobDetails.salary_min,
          salary_max: jobDetails.salary_max,
          relocation_package: !jobDetails?.relocation_package ? [] : jobDetails?.relocation_package,
          country,
          state,
          must_have,
          automated_interview_questions,
        },
      };

      await getPotentialReach();
    } catch (error) {
      alert(errorMessage(error), 'error');
    } finally {
      loading.value = false;
    }
  } else {
    details.value.employer = store.getters['auth/userDetails'].employer;
  }
});
</script>
